<template>
	<aside class="analytics">
		<v-row class="align-center">
			<v-col cols="4"
				><img src="https://www.questpond.com/img/2.png"
			/></v-col>
			<v-col cols="8"
				><h3>{{ courseName }}</h3></v-col
			>
		</v-row>

		<v-row class="py-10">
			<v-col cols="6">
				<p class="analytics__status">{{ status }}</p>
				<p class="analytics__points">{{ score }}/{{ maxScore }}</p>
				<v-btn class="analytics__report-btn admin-primary-button"
					>VIEW QUIZ REPORT</v-btn
				>
			</v-col>
			<v-col cols="6" class="justify-content-center">
				<v-progress-circular
					rotate="180"
					class="analytics__circle"
					:size="150"
					:width="15"
					:value="gradedPercentage"
					color="teal"
				>
					<p class="analytics__circle--done">
						{{ gradedPercentage }}%
					</p>
					<p class="analytics__circle--completed">
						<small>{{
							isCompleted === "1" ? "Completed" : "Not completed"
						}}</small>
					</p>
				</v-progress-circular></v-col
			>
		</v-row>

		<figure class="d-flex flex-row justify-space-between">
			<v-btn
				class="admin-primary-button analytics__utils-btn primary-contrast-background"
				>Download Certificate</v-btn
			>
			<v-btn
				class="admin-primary-button analytics__utils-btn secondary-contrast-background"
				@click="retake"
				>Retake</v-btn
			>
		</figure>
	</aside>
</template>

<script>
import { mapGetters } from "vuex";
export default {
	name: "StudentCourseAnalytics",
	components: {},
	props: ["element"],

	computed: {
		...mapGetters(["publishedCourses", "startedCourses"]),
		gradedPercentage() {
			if (this.score && this.maxScore) return;
			return Number((this.score / this.maxScore) * 100).toFixed(2);
		},
		course() {
			return this.publishedCourses.find(el => el.id === this.courseId);
		},
		courseName() {
			return this.element.course.title;
		},
		score() {
			if (!!this.element?.report_analytics === false) return;
			return this.element?.report_analytics?.score;
		},
		maxScore() {
			if (!!this.element?.report_analytics === false) return;
			return this.element?.report_analytics?.max_scores[0]?.score;
		},
		courseId() {
			return this.element.course.id;
		},
		isCompleted() {
			if (!!this.element.report_analytics === false) return;
			return this.element.report_analytics.max_scores[0]?.report
				?.is_complete;
		}
	},
	data() {
		return {
			status: "PASS",
			value: 75
		};
	},
	methods: {
		async retake() {
			const { id: studentCourseId } = this.startedCourses.find(
				el => el.course_id === this.courseId
			);
			await this.$store.dispatch("setIsLoading", true);
			await this.$router.push({
				name: "Course",
				query: { course: this.courseId, studentCourse: studentCourseId }
			});
		}
	}
};
</script>

<style></style>
