<template>
	<article class="d-flex flex-row flex-wrap mb-10">
		<StudentCourseAnalytics
			class="mb-3"
			v-for="element in displayedCourses"
			:key="element.id"
			:element="element"
		/>
	</article>
</template>

<script>
import { mapGetters } from "vuex";
import StudentCourseAnalytics from "../../components/StudentCourseAnalytics.vue";

export default {
	name: "StudentDashboardTabsAnalytics",
	props: ["searchText"],

	components: {
		StudentCourseAnalytics
	},
	data() {
		return {};
	},
	computed: {
		...mapGetters(["studentAnalytics"]),
		displayedCourses() {
			return this.studentAnalytics.filter(el =>
				String(el.course?.title).includes(this.searchText)
			);
		}
	}
};
</script>
